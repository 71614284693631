@import 'ui/theme.scss';

.error-template {
  max-width: 780px;
  height: 100%;
  margin: 30vh auto 0;

  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;

  @include media(computer) {
    padding: 0 24px;
    margin-top: 28vh;
  }
  &__content {
    margin: 16px 0;

    text-align: center;
    font-size: 24px;
    font-family: selectWeight(medium);

    @include media(tablet) {
      font-size: 16px;
    }
  }
}
