:root {
  --primary-font-medium: 'MontserratMedium', sans-serif;
  --primary-font: 'MontserratRegular', sans-serif;
  --primary-font-semibold: 'MontserratSemiBold', sans-serif;
  --primary-font-bold: 'MontserratBold', sans-serif;

  --color-primary: #FBBB44;
  --color-primary-o30: rgba(251, 187, 68, .3);
  --color-primary-o50: rgba(251, 187, 68, .5);
  --color-primary-o60: rgba(251, 187, 68, .6);
  --color-primary-o90: rgba(251, 187, 68, .9);

  --color-text: #5A5A5C;

  --color-white: #EFEFF1;
  --color-white-o10: rgba(255, 255, 255, .1);
  --color-white-o20: rgba(255, 255, 255, .2);
  --color-white-o30: rgba(255, 255, 255, .3);
  --color-white-o40: rgba(255, 255, 255, .4);
  --color-white-o50: rgba(255, 255, 255, .5);
  --color-gray-o40: rgba(0, 0, 0, .4);
  --color-black: #000;
  --color-black-o40: rgba(0, 0, 0, .4);
  --color-black-o90: rgba(0, 0, 0, .9);
  --color-green: #89C74C;
  --color-red: #DC3545;
  --color-blue: #2BA2EA;
  --color-purple: #7E2C85;

  --color-shadow-black: #050505;

  --color-background-primary: #151111;
  --color-background-primary-o95: rgba(21, 17, 17, .95);
  --color-background-primary-o70: rgba(21, 17, 17, .7);
  --color-background-primary-o49: rgba(21, 17, 17, .49);
  --color-background-primary-o15: rgba(21, 17, 17, .16);
  --color-background-primary-o9: rgba(21, 17, 17, .09);
  --color-background-primary-o5: rgba(21, 17, 17, .05);
  --color-background-primary-o0: rgba(21, 17, 17, 0);
}

$screen-mobile: 768px;
$screen-tablet: 1024px;
$screen-laptop: 1200px;
$screen-computer: 1440px;
$screen-desktop: 1920px;

@function translate-media-condition($condition) {
  $conditionMap: (
    'mobile': '(max-width: #{$screen-mobile})',
    'tablet': '(max-width: #{$screen-tablet})',
    'laptop': '(max-width: #{$screen-laptop})',
    'computer': '(max-width: #{$screen-computer})',
    'desktop': '(max-width: #{$screen-desktop})',
    'largesscreens': '(min-width: #{$screen-desktop})',

    'tabletOnly': '(min-width: #{$screen-mobile}) and (max-width: #{$screen-tablet})',
    'computerOnly': '(min-width: #{$screen-tablet}) and (max-width: #{$screen-computer})',
    'desktopOnly': '(min-width: #{$screen-computer}) and (max-width: #{$screen-desktop})',
  );
  @return map-get($conditionMap, $condition);
}

@mixin media($args...) {
  $query: '';

  @each $arg in $args {
    $op: '';
    @if ($query != '') {
      $op: ' and ';
    }
    $query: $query + $op + translate-media-condition($arg);
  }

  @media #{$query}  {
    @content;
  }
}

@function selectWeight($condition) {
  $conditionMap: (
    'medium': var(--primary-font-medium),
    'normal': var(--primary-font),
    'semibold': var(--primary-font-semibold),
    'bold': var(--primary-font-bold),

    'tt-demibold': var(--primary-font-semibold),
    'tt-bold': var(--primary-font-bold),
  );
  @return map-get($conditionMap, $condition);
}
