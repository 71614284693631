@import 'styles/normalize.scss';
@import 'styles/chatBro.scss';
@import 'ui/theme.scss';

*,
*::before,
*::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


html,
body,
#root {
  height: 100%;
}

html {
  font-size: 16px;
}

body {
  width: 100%;
  letter-spacing: 0.4px;
  overflow-x: hidden;
  line-height: normal;
  font-family: var(--primary-font);
  background-color: var(--color-background-primary);
  color: var(--color-white);
}

a, h1, h2, h3, h4, h5, h6, p {
  -webkit-font-smoothing: antialiased
}

button,
a {
  font-family: var(--primary-font);
  text-decoration: none;
  &:focus {
    outline: none;
  }
}

button {
  background-color: transparent;
  border: none;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

input[type='text'],
input[type='email'],
input[type='password'],
input[type='tel'],
input[type='number'],
input[type='url'],
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input::-ms-clear {
  display: none;
}

::-webkit-scrollbar-button,
::-webkit-resizer,
::-webkit-scrollbar {
  width: 0;
  height: 0;
}


#nprogress {
  .bar {
    height: 3px;

    background: var(--color-primary);

    .peg {
      box-shadow: 5px 5px 10px var(--color-primary),
        2px 2px 5px var(--color-primary);
    }
  }
  .spinner {
    display: none;
  }
}

@import 'styles/utils.scss';
